import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/me/aaa/github/personalsite/src/components/post_template.js";
import ImageSource from "src/components/image";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`the liddo cupcakery`}</h1>
    <p><del parentName="p"><a parentName="del" {...{
          "href": "https://theliddocupcakery.com/"
        }}>{`theliddocupcakery.com`}</a></del>{` `}<strong parentName="p">{`(no longer active)`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://theliddocupcakery.netlify.com/"
      }}>{`theliddocupcakery.netlify.com`}</a>{` (archive)`}</p>
    <p>{`Developed the landing page for the client's cupcake side business. `}<br /><small>{`Nicole the Cupcake Queen 🍰`}</small></p>
    <br />
    <p>{`Source code at `}<a parentName="p" {...{
        "href": "https://github.com/dashed/theliddocupcakery"
      }}>{`github.com/dashed/theliddocupcakery`}</a>{` `}<br /></p>
    <p>{`Built with React.js, and styled-components; and deployed to Netlify.`}</p>
    <br />
    <hr />
    <br />
    <ImageSource src="the-liddo-cupcakery/landing.png" alt="Landing page" mdxType="ImageSource" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      